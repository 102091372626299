.display-1 {
  font-weight: 100 !important;
  color: rgba(209, 31, 31, 0.15) !important;
}

.check-all {
  border: none;
  border-radius: 0px;
  font-size: 2rem !important;
}

textarea {
  width: 100%;
  height: 4rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
  padding-top: 1rem;
  border: none;
}

textarea::placeholder {
  font-weight: 100;
  opacity: 0.3;
}

textarea:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.todo-input {
  font-size: 2rem;
  font-weight: 300;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.list-name {
  font-weight: 300;
  color: rgba(209, 31, 31, 0.8);
}

input {
  width: 100%;
  border: none;
}

input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

input::placeholder {
  font-weight: 100;
  opacity: 0.3;
}

input.todo-completed {
  color: lightgray;
  font-weight: 200;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.height4rem {
  height: 4rem;
}

.check-all {
  font-size: 1.5rem;
  font-weight: 150;
  opacity: 0.5;
}

.check-all:hover {
  font-weight: 500;
  opacity: 0.7;
  color: green;
  cursor: pointer;
}

.check-one,
.delete-one {
  opacity: 0.35;
}

.check-one:hover {
  opacity: 0.7 !important;
  color: green;
  cursor: pointer;
}

.delete-one:hover {
  opacity: 0.7 !important;
  color: red;
  cursor: pointer;
}

.todo-row,
.filter-bar {
  border-top: 1px solid rgba(128, 128, 128, 0.3);
}

.todoez-home-link {
  text-decoration: none;
}

.small-top-padding {
  padding-top: 0.15rem !important;
}

.share-button {
  box-shadow: none !important;
  outline: 0px !important;
}